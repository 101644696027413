<template>
  <h2 class="app-bar-route-name" v-text="routeName"></h2>
</template>

<script>
export default {
  name: 'AppBarRouteName',
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-bar-route-name {
  font-size: 22px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: var(--green-200);
}
</style>
